import React from 'react'
// import bed from '/bed.png';
// import sink from '/sink.png';
// import square from '/square.png';
// import wide from '/wide.png';
import rosaritoExt from '../../../images/rosarito/2RosaritoExt.jpg'
import RosaritoSala from '../../../images/rosarito/6RosaritoSala.jpg'
// import c4 from '/C-4.jpg';
// import IMG_3588 from './IMG_3588.jpg';
// import m4p1 from '/m4p1.jpg';
// import m4p2 from '/m4p2.jpg';
// import m4p3 from '/m4p3.jpg';
// import rosaritoExt from '../../../images/rosarito/2RosaritoExt.png'
// import rosaritoExt from '../../../images/rosarito/2RosaritoExt.png'
// import rosaritoExt from '../../../images/rosarito/2RosaritoExt.png'
import * as styles from '../../../styles/modelo.module.css'
import Layout from '../../../components/layout';
import BannerExample from '../../../components/carrousel';
import Mapa from '../../../components/mapa';



import { Grid } from "@material-ui/core";
import { Helmet } from "react-helmet"
const images = [
    { img: rosaritoExt, title: 'Modelo Rosarito Exterior', rows: 3, cols: 6 },
    { img: RosaritoSala, title: 'Modelo Rosarito Exterior', rows: 3, cols: 6 },
    { img: "/IMG_3588.jpg", title: 'Modelo Rosarito Exterior', rows: 3, cols: 6 },
]


export default function Rosarito() {
    return (
        <Layout style={{ maxWidth: "100%" }}>

                  <Helmet>
                <title>Aries Online - Rosarito</title>
{/*                 
                
            <script src="https://polyfill.io/v3/polyfill.min.js?features=default"></script>
                
                <script type="module" src="../../../components/mapa.tsx"></script> */}
            </Helmet>

          <div className={styles.modeloCont}>
            <div className={styles.modeloMain}>
                  <div className={styles.mainImg} style={{ backgroundImage: `url('${rosaritoExt}')` }}></div>

              <div className={styles.mainInfo}>
                <h1 className={styles.titleModelo}>Modelo</h1>
                <h1 className={styles.titleModeloName}>Rosarito Beach</h1>
                <h2 className={styles.titlePriceModelo}>Desde 368,400 USD*</h2>

                        <div className={styles.featuresCont}>
                            <div className={styles.features1}>
                                <div className= {`${styles.feature} ${styles.itemsModelsMobile}`}>
                                    <img src={"/bed.png"} alt="cama" className= {styles.iconModelLeft}/>
                                    <p>Recámaras <br/>3</p>
                                </div>
                                <div className= {`${styles.feature} ${styles.itemsModelsMobile}`}>
                                    <img src={"/sink.png"} alt="baño" className= {styles.iconModelLeft} />
                                    <p>Baños Completos <br/>3</p>
                                </div>
                                <div className= {`${styles.feature} ${styles.itemsModelsMobile}`}>
                                    <img src={"/sink.png"} alt="baño" className= {styles.iconModelLeft}/>
                                    <p>Medios Baños <br/>2</p>
                                </div>
                            </div>

                  <div className={styles.features2}>
                    <div className={`${styles.feature} ${styles.paddingColumnModel} ${styles.itemsModelsMobile} ${styles.containerItemAreaTotal } `}>
                      <img src={"/square.png"} alt="area" className= {styles.iconModelRight} />
                      <p className={ styles.paddingDescriptionModel }>Área Total <br />376 m²</p>
                    </div>
                    <div className={`${styles.feature} ${styles.paddingColumnModel} ${styles.itemsModelsMobile}`}>
                      <img src={"/wide.png"} alt="terreno"  className= {styles.iconModelRight}/>
                      <p className={ styles.paddingDescriptionModel } >Terreno Incluído<br />240 m²</p>
                    </div>
                  </div>
                </div>

                        <div className={styles.tyc}>
                            <p className={styles.warningTextDescriptionModel}>*Consulte términos y condiciones.</p>
                            <p className={styles.warningTextDescriptionModel}>Precios en dólares estadounidenses (USD).</p>
                            <p className={styles.warningTextDescriptionModel}>Aplican restricciones y cambios sin previo aviso.</p>
                        </div>
                    </div>

            </div>

            <div className={styles.modeloImgs}>
              {/* <img src={RosaritoSala} alt="interior" />
                <img src={c4} alt="interior" />
                <img src={IMG_3588} alt="interior" /> */}

                    <BannerExample images={images} galeryStyleCarrusel={true}></BannerExample>
                </div>
                <Grid className={styles.divIframe} item xs={12} sm={12} md={12} lg={12} style={{ margin: "auto" }}>
                    <iframe className={styles.iframe} allowFullScreen={true} src='https://my.matterport.com/show/?m=JQTmuhPVXaB'>

                    </iframe>
                </Grid>
                <Grid className={styles.divIframe} item xs={12} sm={12} md={12} lg={12} style={{ margin: "auto" }}>
                    <Mapa></Mapa>
                    {/* <App></App> */}
                    {/* <div id="root"></div> */}
                </Grid>
                {/* <div className={styles.plantasCont}>
                <div className={styles.planta}>
                   <img src={m4p1} alt="planta" />
                    <p>Planta Baja</p>
                </div>
                <div className={styles.planta}>
                    <img src={m4p2} alt="planta" />
                    <p>Planta Alta</p>
                </div>
                <div className={styles.planta}>
                    <img src={m4p3} alt="planta" />
                    <p>Terraza</p>
                </div>
            </div>

            <div>
            <div class="row" style={{margin:"20px 0px 10px 0px", display:"flex", justifyContent:"center"}}>
			<div style={{width: "95%"}}>
				{/* <div class="col-xs-12 col-sm-3 col-md-2 col-lg-2 text-center"> * /}
                <div>
					 <button>{/* class="btn btn-block buttonMaps tex-center" * /}
						<img src="https://testga.blob.core.windows.net/media/42615/maps.png" style={{height: "20px", margin:"0px", display: "inline"}} alt="button google maps" />
						<a href="https://www.google.com/maps?q=32.323293,-117.044149" target="_blank">
							google maps
						</a>
					</button>
                    {/* north: 32.33857619546528, south: 32.30508851090992, east: -117.00693928125736, west: -117.07387308792488 * /}
				</div>
                 {/ * class="col-xs-12 col-sm-9 col-md-10 col-lg-10" * /}
				<div >
					<h4 class="text-center" style={{padding:"0px 15px 0px 0px"}}>
						{/ * @Contenedor.GetPropertyValue("descripcionubicacion") * /}
					</h4>
				</div>
            </div> 
        </div>

            </div>*/}
            </div>


        </Layout>

    )
}
